import { CustomerDashboardCard } from "./CustomerDashboardCard"
import { CustomerPageLayout } from "../CustomerPageLayout"
import { memo } from "react"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/pages/account"
import type { PageProps } from "@root/types/global"

const CustomerDashboard: React.FC<PageProps<Props>> = ({ location, page }) => {
  const hasSections = !!page?.sections?.length

  return (
    <>
      <CustomerPageLayout title={page?.title} content={page?.content} location={location} subtitle={page?.subtitle} showRewardsBanner>
        <CustomerDashboardCard tiles={page?.tiles} />
      </CustomerPageLayout>
      {hasSections && <Sections page={{ content: page?.sections }} />}
    </>
  )
}

export default memo(CustomerDashboard)
