import { graphql, PageProps } from "gatsby"
import { withAuthentication } from "@app/hoc/Authentication"
import Page from "@components/Customer/Dashboard/CustomerDashboard"

export type Props = PageProps<GatsbyTypes.PageAccountDashboardQuery, GatsbyTypes.PageAccountDashboardQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageAccountDashboard {
    page: sanityPageAccountDashboard {
      title
      subtitle
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      tiles: _rawTiles(resolveReferences: { maxDepth: 3 })
      sections: _rawSections(resolveReferences: { maxDepth: 8 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default withAuthentication(Component)
