import { memo, useEffect, useMemo } from "react"
import { SimpleGrid, Center, LinkBox, LinkOverlay, VStack, Text } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { CustomImage } from "@components/Image"
import { useRoutes } from "@app/hooks/useRoutes"
import { useRewards } from "@app/hooks/useRewards"
import { useSubscriptionContext } from "@app/providers/subscription"

type CustomerDashboardCardProps = {
  tiles?: GatsbyTypes.SanityPageAccountDashboard["tiles"]
}

type CustomerDashboardTileProps = {
  tile?: GatsbyTypes.SanityImageLink
}

const CustomerDashboardTile: React.FC<CustomerDashboardTileProps> = memo(({ tile }) => {
  const { urlResolver } = useRoutes()
  const resolvedUrl = useMemo(() => urlResolver(tile?.link), [tile?.link, urlResolver])
  const { checkIfShouldRefer, refreshRewardsUI, openReferralModal } = useRewards()
  const { portalUrl, checkSubscriptionText } = useSubscriptionContext()

  // See https://developers.loyaltylion.com/sdk/components/referral-modal
  const isReferAFriendTile = checkIfShouldRefer(tile?.link?.title)
  const tileLink = isReferAFriendTile ? "#" : resolvedUrl?.url
  const isSubscriptions = checkSubscriptionText(tile?.link?.title)

  useEffect(() => {
    refreshRewardsUI()
  }, [refreshRewardsUI])

  return (
    <LinkBox as={Center} bg="brand.lightSand" py={[6, 8]} px={0} display={isSubscriptions && !portalUrl ? "none" : "block"}>
      <VStack>
        <CustomImage src={tile?.image?.asset?.url} alt={tile?.image?.alt} />

        {isReferAFriendTile && (
          <LinkOverlay as="a" href="#" onClick={openReferralModal}>
            <Text mt={6} fontSize={["md", "xl"]}>
              {resolvedUrl?.title}
            </Text>
          </LinkOverlay>
        )}
        {!isReferAFriendTile && (
          <LinkOverlay as={CustomLink} to={isSubscriptions ? portalUrl : tileLink} title={resolvedUrl?.title} external={!!isSubscriptions}>
            <Text mt={6} fontSize={["md", "xl"]}>
              {resolvedUrl?.title}
            </Text>
          </LinkOverlay>
        )}
      </VStack>
    </LinkBox>
  )
})

const CustomerDashboardCard: React.FC<CustomerDashboardCardProps> = ({ tiles }) => {
  const hasTiles = !!tiles?.length

  if (!hasTiles) return null

  return (
    <SimpleGrid columns={[2, 2, 2, 3]} spacing={3}>
      {tiles?.map(tile => (
        <CustomerDashboardTile key={tile?._key} tile={tile} />
      ))}
    </SimpleGrid>
  )
}

const MemoCustomerDashboardCard = memo(CustomerDashboardCard)
export { MemoCustomerDashboardCard as CustomerDashboardCard }
